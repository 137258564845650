/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "../../DefaultNavbar";
import DefaultFooter from "../../DefaultFooter";

// About Us page sections

// Routes
import routes from "../../../routes";
import footerRoutes from "../../../app/footer.routes";

// Images
import bgImageMobile from "../../../assets/images/bg-terms-of-service-mobile.jpg";
import { selectImageWithWidth } from "../../../utils/CommonUtils";

import { downloadPointieData } from "../../../data/DownloadButtons";

const TermsOfService = () => {
  const bgImg = selectImageWithWidth(bgImageMobile, bgImageMobile);
  return (
    <>
      <DefaultNavbar routes={routes} action={downloadPointieData} transparent light />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Terms of Service
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Please read these Terms of Service carefully before using the Pointie Extension or
              website. These terms outline your rights and obligations when using our service. By
              accessing or using our Extension or website, you agree to be bound by these terms. If
              you do not agree with any part of these terms, you may not use our service.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography variant="h4">Service and Uptime</MKTypography>
        <MKTypography variant="body2" mb={1}>
          &nbsp;Pointie provides a free service, and we strive to maintain uninterrupted access to
          our service. However, we do not provide any warranty or guarantee regarding the service or
          uptime. Your use of the site is at your own risk.
        </MKTypography>

        <MKTypography variant="h4">Offer and Availability</MKTypography>
        <MKTypography variant="body2" mb={1}>
          &nbsp;The offer and availability displayed on our site are accurate. However, please note
          that these details are subject to change. The offer and availability information shown at
          the time of purchase may apply to your purchase of the product.
        </MKTypography>

        <MKTypography variant="h4">Limitation of Liability</MKTypography>
        <MKTypography variant="body2" mb={1}>
          &nbsp;Pointie and its team members shall not be liable for any direct, indirect,
          incidental, consequential, or special damages arising out of or in connection with your
          use of the website or reliance on any information provided.
        </MKTypography>

        <MKTypography variant="h4">Changes to the Terms</MKTypography>
        <MKTypography variant="body2" mb={1}>
          &nbsp;We may update or modify these Terms of Service from time to time without prior
          notice. It is your responsibility to review the most current version of the terms
          regularly. Continued use of the service after any modifications indicates your acceptance
          of the updated terms.
        </MKTypography>

        <MKTypography variant="h4">Contact Information</MKTypography>
        <MKTypography variant="body2" mb={3}>
          &nbsp;If you have any questions or concerns about our Terms of Service, please contact us
          at <a href="mailto:letspointie@gmail.com">letspointie@gmail.com</a>.
        </MKTypography>

        <MKTypography variant="body1" mb={1}>
          Update: July 18, 2023
        </MKTypography>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default TermsOfService;
