/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "../../../DefaultNavbar";
import DefaultFooter from "../../../DefaultFooter";

// Routes
import routes from "../../../../routes";
import footerRoutes from "../../../../app/footer.routes";

// Image
import bgImage from "../../../../assets/images/bg-contact-us.jpg";
import bgImageMobile from "../../../../assets/images/bg-contact-us-mobile.jpg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CoffeeIcon from "@mui/icons-material/Coffee";
import { selectImageWithWidth } from "../../../../utils/CommonUtils";

import { downloadPointieData } from "../../../../data/DownloadButtons";

function ContactUs() {
  const bgImg = selectImageWithWidth(bgImage, bgImageMobile);
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar routes={routes} action={downloadPointieData} />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{
              backgroundImage: `url(${bgImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                If you have any questions or suggestions, please&nbsp;
                <a href="mailto:letspointie@gmail.com" target="_blank" rel="noreferrer">
                  email
                </a>
                &nbsp;us.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={1}>
                  <Grid item md={4} xl={12}>
                    <MKButton
                      href="https://www.facebook.com/profile.php?id=100093986463973"
                      target="_blank"
                      variant="gradient"
                      color="info"
                      style={{ width: "100%" }}
                    >
                      &nbsp;
                      <FacebookIcon />
                      &nbsp;&nbsp;Facebook&nbsp;&nbsp;
                    </MKButton>
                  </Grid>
                  <Grid item md={4} xl={12}>
                    <MKButton
                      href="https://www.youtube.com/@PointieCa"
                      target="_blank"
                      variant="gradient"
                      color="info"
                      style={{ width: "100%" }}
                    >
                      &nbsp;&nbsp;
                      <YouTubeIcon />
                      &nbsp;&nbsp;Youtube&nbsp;&nbsp;
                    </MKButton>
                  </Grid>
                  <Grid item md={4} lg={12}>
                    <MKButton
                      href="https://www.instagram.com/letspointie/"
                      target="_blank"
                      variant="gradient"
                      color="info"
                      style={{ width: "100%" }}
                    >
                      <InstagramIcon />
                      &nbsp;&nbsp;Instagram
                    </MKButton>
                  </Grid>
                  <Grid item md={4} lg={12}>
                    <MKButton
                      href="https://www.buymeacoffee.com/letspointie"
                      target="_blank"
                      variant="gradient"
                      color="info"
                      style={{ width: "100%" }}
                    >
                      <CoffeeIcon />
                      &nbsp;&nbsp;Buy me a coffee
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
