import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import screenshotImg from "../../../../assets/images/pointie_screenshot.png";
import screenshot2Img from "../../../../assets/images/pointie_screenshot2.png";
import MKTypography from "../../../components/MKTypography";
const EmbedScreenshotAndVideo = () => {
  return (
    <Container>
      <Grid container item lg={12} justifyContent="center" sx={{ mx: "auto", textAlign: "center" }}>
        <MKTypography variant="h3" mb={1}>
          See how much you can earn for each offer and activate them with a single click
        </MKTypography>
        <img src={screenshotImg} style={{ width: "100%" }} alt="Pointie main UI" />
      </Grid>
      <br />
      <Grid container item lg={12} justifyContent="center" sx={{ mx: "auto", textAlign: "center" }}>
        <MKTypography variant="h3" mb={1}>
          View offer history from the past 30 days to see if now is the right time to grab a deal
        </MKTypography>
        <img src={screenshot2Img} style={{ width: "100%" }} alt="Pointie offer history" />
      </Grid>
    </Container>
  );
};

export default EmbedScreenshotAndVideo;
