import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const dataUrl = "https://cdn.pointie.ca/pointieprovider/provider/all.json";
export const fetchProviders = createAsyncThunk("providers/fetchProviders", async (_, thunkAPI) => {
  try {
    const response = await axios.get(dataUrl);
    return response.data; // 성공 시 데이터 반환
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message || "Failed to fetch data");
  }
});

// Slice 생성
const providerSlice = createSlice({
  name: "providers",
  initialState: {
    data: {},
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchProviders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default providerSlice.reducer;
