// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Coffee from "@mui/icons-material/Coffee";

// Material Kit 2 React components
import MKTypography from "../contents/components/MKTypography";

// Images
import logoCT from "../assets/images/pointie_logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Let's Pointie",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/profile.php?id=100093986463973",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/letspointie",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@PointieCa",
    },
    {
      icon: <Coffee />,
      link: "https://www.buymeacoffee.com/letspointie",
    },
  ],
  menus: [
    {
      name: "about pointie",
      items: [{ name: "update notes", href: "/about-pointie/announcements/update-notes" }],
    },
    {
      name: "app store",
      items: [
        {
          name: "Chrome extension",
          href: "https://chrome.google.com/webstore/detail/lets-pointie/mlabiphbgnehhigedcdklojghfpjhomi",
        },
      ],
    },
    {
      name: "help & contact",
      items: [
        { name: "contact us", href: "/help-contact/contact/contact-us" },
        { name: "FAQ", href: "/help-contact/help/faq" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms of service", href: "/legal/terms-of-service" },
        { name: "privacy policy", href: "/legal/privacy-policy" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright &copy; {date} <b>Pointie</b>. All rights reserved
    </MKTypography>
  ),
};
